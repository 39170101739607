<template>
    <v-row no-gutters>
            <v-col cols="12">
          <v-toolbar dark color="primary pl-2 pr-2" dense>
            <v-toolbar-title>Setup Note Information</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('closeDialog')">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
        <v-col cols="12" md="8" class="pl-1 pt-1 pb-1">
          <v-simple-table
            :fixed-header="true"
            class="elevation-1 align-center"
            height="91vh"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center white--text primary">No.</th>
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Note</th>
                   <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Remark</th>
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in noteList"
                  :key="index"
                  @click="selectedOne = item"
                  :style="{
                    backgroundColor:
                      item.noteId == selectedOne.noteId
                        ? '#def3ff'
                        : 'transparent',
                  }"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{ item.note }}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{ item.remark }}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">
                    <v-btn small icon color="green" @click="clickEdit(item)">
                      <v-icon>edit</v-icon></v-btn
                    >
                    <v-btn small icon color="red" @click="clickDelete(item)">
                      <v-icon>delete</v-icon></v-btn
                    >
                  </td>
                </tr>
                <v-divider />
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" md="4" class="pl-2">
          <v-row class="align-center login-full" no-gutters>
            <v-col>
              <v-card outlined class="mr-2 pl-2 pr-2 pt-2">
                <v-form v-model="valid">
                  <v-row no-gutters>
                    
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="noteDto.note"
                        label="Note"
                        dense
                        outlined
                        filled
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="noteDto.remark"
                        label="Remark"
                        dense
                        outlined
                        filled
                      />
                    </v-col>
                    <v-col cols="12" md="12" class="text-right pb-2">
                      <v-btn
                        class="blue white--text"
                        @click="saveNote()"
                        :disabled="!valid"
                        >{{ saveOrupdate }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
            </v-row>
            </v-col>
            <v-col>
          <v-dialog
          v-model="dialogDelete"
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 white--text red">
              Delete
            </v-card-title>
    
            <v-card-text class="text-h6 ">
               Are you sure to delete({{deleteNote.note}})?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDelete = false"
              >
                CANCEL
              </v-btn>
              <v-btn
              dark
                class="red"
                text
                @click="clickDeleteDialog()"
              >
                DELETE
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-col>
        </v-row>
    </template>
    <script>
    import noteService from "../service/NoteService";
    export default {
      data: () => ({
        valid: false,
        selectedOne:{},
        saveOrupdate: "SAVE",
        noteDto:{},
        dialogDelete:false,
        deleteNote:{}
      }),
       props: {noteList:Array},
      mounted: function() {
      },
      methods: {
        saveNote:function(){
            if (
            this.noteDto.note != undefined &&
            this.noteDto.note.trim() != ""
          ) {
            if (this.saveOrupdate == "SAVE") {
                noteService
                .addNote(this.noteDto)
                .then((response) => {
                  this.noteDto = {};
                  this.$emit('loadNoteList');
                  this.saveOrupdate = "SAVE";
                  this.$swal({
                    title: "Successful",
                    text: "Save,Success!",
                    type: "success",
                    timer: 500,
                  });
                })
                .catch((error) => {
                  this.$swal("Fail!", error.response.data.message, "error");
                });
            } else {        
                noteService
                .updateNote(this.noteDto)
                .then((response) => {
                    this.$emit('loadNoteList');
                    this.saveOrupdate = "SAVE";
                  this.noteDto={};
                  this.$swal({
                    title: "Successful",
                    text: "Update,Success!",
                    type: "success",
                    timer: 500,
                  });
                })
                .catch((error) => {
                  this.$swal("Fail!", error.response.data.message, "error");
                });
            }
          }
        },
        clickEdit: function (item) {
          this.noteDto = Object.assign({}, item);
          this.saveOrupdate = "UPDATE";
        },
        clickDelete:function(item){
          this.deleteNote = Object.assign({}, item);
          this.dialogDelete = true;
        },
        clickDeleteDialog:function(){
          this.dialogDelete = false;
          noteService
                .deleteNote(this.deleteNote)
                .then((response) => {
                    this.$swal({
                    title: "Successful",
                    text: "Delete,Success!",
                    type: "success",
                    timer: 500,
                  });
                    this.$emit('loadNoteList');
                  this.saveOrupdate = "SAVE";
                  
                })
                .catch((error) => {
                  this.$swal("Fail!", error.response.data.message, "error");
                });
          
        }
      },
      watch: {
      },
      components: {}
    };
    </script>
    <style scoped>
    .v-data-table td,
    .v-data-table th {
      padding: 0 5px !important;
    }
    .login-full {
      height: 91vh;
    }
    </style>