<template>
    <v-row no-gutters>
      
      <v-col cols="12" md="7" class="pl-1 pt-1 pb-1">
        <v-simple-table
          :fixed-header="true"
          class="elevation-1 align-center"
          height="90vh"
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center white--text primary">No.</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Product Code</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Remark</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Qty</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Price</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Amount</th>
                 <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Detail</th>
                 <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <!-- <th class="text-center white--text primary">Remark</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th> -->
                <th class="text-center white--text primary">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in tempList"
                :key="index"
                @click="selectedOne = item"
                :style="{
                  backgroundColor:
                    item.invDetailId == selectedOne.invDetailId
                      ? '#def3ff'
                      : 'transparent',
                }"
              >    
             
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-left">{{ item.note.note}}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-left">{{ item.remark}}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.qty }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.price }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.amount }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">
                  {{item.detail}}
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <!-- <td class="text-center">{{ item.remark }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td> -->
                <td class="text-center">
                  <v-btn small icon color="green" @click="clickEdit(item)">
                    <v-icon>edit</v-icon></v-btn
                  >
                  <v-btn small icon color="red" @click="clickDelete(item)">
                    <v-icon>delete</v-icon></v-btn
                  >
                </td>
              </tr>
              <v-divider />
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      
      <v-col cols="12" md="5" class="pl-2">
        <v-row class="align-center login-full" no-gutters>
          <v-col>
            <v-card outlined class="mr-2 pl-2 pr-2">
              <v-form v-model="valid">
                <v-row no-gutters>
                  <v-col cols="12" md="4" class="mt-2">
                <v-menu
                  v-model="requestMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="receivedDate"
                      label="Received Date"
                      readonly
                      v-on="on"
                      name="SDDSFF"
                      dense
                      outlined
                      filled
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="requestPicker"
                    :max="new Date().toISOString().substr(0, 10)"
                    @input="requestMenu = false"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" class="pl-2 pt-2">
                    <v-autocomplete
                      v-model="exchangeDto"
                      item-text="symbols"
                      :items="exchangeList"
                      label="Currency Sign"
                      name="symbols"
                      return-object
                      required
                      dense
                      outlined
                      filled
                    >
                      <template v-slot:append-outer>
                        <v-slide-x-reverse-transition mode="out-in">
                           <v-btn small icon color="green" @click="dialogExchange=true">
                            <v-icon>add_circle</v-icon></v-btn
                            >
                        </v-slide-x-reverse-transition>
                      </template>
                    </v-autocomplete>
                  </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    :rules="[(v) => !!v || 'required']"
                    v-model="customerDto"
                    item-text="customerName"
                    :items="customerList"
                    label="Customer"
                    name="customerName"
                    return-object
                    required
                    dense
                    outlined
                    filled
                  >
                </v-autocomplete>
              </v-col>
               <v-col cols="12" md="4" class="pl-2">
                <v-text-field v-model="phone" label="Phone"  filled outlined dense></v-text-field>
              </v-col>
               <v-col cols="12" md="4" class="pl-2">
                <v-text-field v-model="address" label="Address"  filled outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
              <v-card  outlined class="mr-1 pl-1 pr-1 mb-1">
                 <v-row no-gutters>
              <v-col cols="12" md="6" class="pt-1 pl-1 pr-2">
                    <v-autocomplete
                      v-model="noteDto"
                      item-text="note"
                      :items="noteList"
                      label="Product Code"
                      name="note"
                      return-object
                      required
                      dense
                      outlined
                      filled
                      ref="noteRef"
                      @keyup.enter="$refs.remarkRef.focus()" 
                      
                    >
                      <template v-slot:append-outer>
                        <v-slide-x-reverse-transition mode="out-in">
                          <v-btn small icon color="green" @click="dialogNote=true">
                            <v-icon>add_circle</v-icon></v-btn
                            >
                        </v-slide-x-reverse-transition>
                      </template>
                    </v-autocomplete>
                  </v-col>
                   <v-col cols="12" md="6" class="pt-1">
                <v-text-field v-model="remark" label="Remark"  filled outlined dense   ref="remarkRef"
                      @keyup.enter="$refs.qtyRef.focus()" ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pl-2">
                <v-text-field v-model.number="qty" label="Qty" type="number" filled outlined dense   ref="qtyRef"
                      @keyup.enter="$refs.priceRef.focus()" ></v-text-field>
              </v-col>
               <v-col cols="12" md="4" class="pl-2">
                <v-text-field v-model.number="price" label="Price" type="number" filled outlined dense   ref="priceRef"
                     ></v-text-field>
              </v-col>
               <v-col cols="12" md="4" class="pl-2">
                <v-text-field v-model.number="amount" label="Amount" type="number" readonly filled outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="10" class="pl-2">
                <v-textarea v-model="detailRemark" rows="3"
          row-height="20" label="Detail" filled outlined dense  
                      ></v-textarea>
              </v-col>
               <v-col cols="12" md="2" class="text-right">
                    <v-btn class="primary" @click="clickAdd()">{{addOrupdate}}</v-btn>
                  </v-col>
              </v-row>
              </v-card>
              </v-col>
               <v-col cols="12" md="3" class="pl-2 pt-2">
                <v-text-field v-model.number="totalPrice" label="Total" readonly type="number" filled outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="pl-2 pt-2">
                  <v-autocomplete
                    :rules="[(v) => !!v || 'required']"
                    v-model="cashtype"
                    item-text="typeName"
                    :items="cashtypeList"
                    label="Cash Type"
                    name="typeName"
                    return-object
                    required
                    dense
                    outlined
                    filled
                  >
                </v-autocomplete>
              </v-col>
               <v-col cols="12" md="4" class="pl-2 pt-2">
                <v-text-field v-model.number="advancePaid" label="Payment"  type="number" filled outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pl-2">
                <v-text-field v-model.number="otherPayment" readonly label="Other Payment"  type="number" filled outlined dense></v-text-field>
              </v-col>
               <v-col cols="12" md="3" class="pl-2">
                <v-text-field v-model.number="balance" readonly label="Balance" type="number" filled outlined dense></v-text-field>
              </v-col>
               <v-col cols="12" md="6" class="pl-2">
                <v-text-field v-model="invRemark" label="Remark"  filled outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
              <v-radio-group v-model="rgType"  row>
                <v-radio label="Invoice" v-if="saveOrupdateAll=='SAVE'||(saveOrupdateAll=='UPDATE' && invoiceDto.type=='INVOICE')" value="INVOICE"></v-radio>
                <v-radio label="Expenses" v-if="saveOrupdateAll=='SAVE'||(saveOrupdateAll=='UPDATE' && invoiceDto.type=='EXPENSE')"  value="EXPENSE"></v-radio>
               
              </v-radio-group>
            </v-col>
              <v-col cols="12" md="4" class="text-right pr-2 pb-2">
                          <v-btn color="primary" outlined @click="clickAllSave()">{{saveOrupdateAll}}</v-btn>
</v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-col>      
      <v-col>
          <v-bottom-sheet v-model="dialogExchange" fullscreen scrollable>
        <v-sheet>
          <exchange
            @closeDialog="dialogExchange=false"
            :exchangeList = "exchangeList"
            @loadExchangeList="exchangeListMethod"
          />
        </v-sheet>
      </v-bottom-sheet>
        </v-col>        
        <v-col>
          <v-bottom-sheet v-model="dialogNote" fullscreen scrollable>
        <v-sheet>
          <note
            @closeDialog="dialogNote=false"
            :noteList = "noteList"
            @loadNoteList="noteListMethod"
          />
        </v-sheet>
      </v-bottom-sheet>
        </v-col>
    </v-row>
  </template>
  <script>
  import invoiceService from "../service/InvoiceService";
  import exchangeService from "../service/ExchangeService";
  import noteService from "../service/NoteService";
  import customerService from "../service/CustomerService";
  import billService from "../service/BillService";
  import exchange from "@/components/Exchange.vue";
  import note from "@/components/Note.vue";
  export default {
    data: () => ({
    requestMenu: false,
    requestPicker: new Date().toISOString().substr(0, 10),
    receivedDate: "",
    exchangeList:[],
    exchangeDto:{},
    noteList:[],
    noteDto:{},
    customerList:[],
    customerDto:{},
    dialogExchange:false,
    dialogNote:false,
    exRate:0,
    remark:"",
    address:"",
    phone:"",
    qty:0,
    price:0,
    amount:0,
    advancePaid:0,
    otherPayment:0,
    balance:0,
    invRemark:"",
    addOrupdate:"ADD",
    saveOrupdateAll:"SAVE",
    tempList: [],
    selectedOne:{},
    invDetailId:1,
    invDetailDto:{},
    invoiceDto:{},
    totalPrice:0,
    deleteDetail:{},
    invoiceId:0,
    allcashtypeList: [],
    allcashtype: {},
    cashtypeList: [],
    cashtype: {typeId:6,unitAmount:1},
    invPaymentDto:{},
    invPaymentList:[],
    detailRemark:"",
    noteIndex:0,
    rgType:"INVOICE",
    }),
    props: {},
    mounted: function () {
      this.receivedDate = this.formatDate(this.requestPicker); 
      this.noteListMethod();
      this.exchangeListMethod();
      this.customerListMethod();
      this.getCashListMethod();
     let invoiceId = this.$route.query.invoiceId;
    if (invoiceId != undefined) {
      this.invoiceId = invoiceId;
      this.getInvoiceDetail();
    }
    },
    methods: {
      getInvoiceDetail:function(){
      invoiceService
          .getInvoiceById(this.invoiceId)
          .then((response) => {
            if(Number(response.invoiceId)>0){
            this.saveOrupdateAll = "UPDATE";
            this.invoiceDto = Object.assign({},response);   
            this.tempList=this.invoiceDto.invDetail;
            this.exchangeDto=this.invoiceDto.exchange;
            this.receivedDate=this.invoiceDto.receivedDate;
            this.totalPrice=this.invoiceDto.totalAmount;
            this.advancePaid=this.invoiceDto.advancePaid;
            this.otherPayment = this.invoiceDto.otherPayment;
            this.balance=this.invoiceDto.balance;
            this.invRemark=this.invoiceDto.remark;  
            this.invPaymentList=this.invoiceDto.invPayment; 
            let tempType = {};
            tempType.typeId = this.invoiceDto.invTypeId;
            tempType.typeName = this.invoiceDto.invTypeName;
            tempType.unitAmount=this.invoiceDto.exchangeRate;
            this.cashtype = Object.assign({},tempType);
            this.rgType=this.invoiceDto.type;
            this.customerDto=this.invoiceDto.customer;
          }          
          })
          .catch((error) => {
            this.$swal("Fail!", error.response.data.message, "error");
          });
    },
    clickAllSave:function(){
      if(this.tempList.length>0){    
        this.invPaymentList.splice(0);  
      this.invoiceDto.customer=this.customerDto;
      this.invoiceDto.exchange=this.exchangeDto;
      this.invoiceDto.receivedDate=this.receivedDate;
      this.invoiceDto.exchangeRate=this.cashtype.unitAmount;
      this.invoiceDto.totalAmount=this.totalPrice;
      this.invoiceDto.advancePaid=this.advancePaid;
      this.invoiceDto.otherPayment = this.otherPayment;
      this.invoiceDto.balance=this.balance;
      this.invoiceDto.remark=this.invRemark;
      this.invoiceDto.invDetail=this.tempList;
      this.invPaymentDto.typeId=this.cashtype.typeId;
      this.invPaymentDto.payment=this.advancePaid;
      this.invPaymentDto.exchangeRate=1;
      this.invPaymentDto.paymentMMK=this.advancePaid;
      // this.invPaymentDto.balance=this.advancePaid*this.cashtype.unitAmount;
      this.invPaymentDto.symbols=this.exchangeDto.symbols;
      this.invPaymentDto.remark=this.invRemark;
      this.invPaymentList.push(this.invPaymentDto);
      this.invoiceDto.invPayment=this.invPaymentList;
      this.invoiceDto.type=this.rgType;
      if(this.rgType=="INVOICE"){
        this.invoiceDto.type = "INVOICE";
          }
else{
  this.invoiceDto.type = "EXPENSE";
}
      if(this.saveOrupdateAll == "SAVE"){
        invoiceService
        .addInvoice(this.invoiceDto)
        .then((response) => {
            this.tempList.splice(0);
            this.totalPrice = 0;
            this.balance = 0;
            this.advancePaid = 0;
            this.otherPayment = 0;
            this.invRemark = "";
            this.rgType="INVOICE";
            // this.exchangeListMethod();
            // this.noteListMethod();
            // this.customerListMethod();
            // this.getCashListMethod();
            this.$swal({
                title: "Successful",
                text: "Save,Success!",
                icon: "success",
                timer: 500,
              });
          })
          .catch((error) => {
            this.$swal("Fail!", error.response.data.message, "error");
          });
      }else{
        invoiceService
        .updateInvoice(this.invoiceDto)
        .then((response) => {
            this.tempList.splice(0);
            this.totalPrice = 0;
            this.balance = 0;
            this.advancePaid = 0;
            this.otherPayment = 0;
            this.invRemark = "";  
            this.rgType="INVOICE";       
            // this.exchangeListMethod();
            // this.noteListMethod();
            // this.customerListMethod();
            // this.getCashListMethod();  
            this.$router.push({
              name: "invoice"
            }); 
            this.$swal({
                title: "Successful",
                text: "Save,Success!",
                icon: "success",
                timer: 500,
              });
              this.saveOrupdateAll = "SAVE";
          })
          .catch((error) => {
            this.$swal("Fail!", error.response.data.message, "error");
          });
          
      }
      }else{
         this.$swal("Warning!", "Please add one row for Invoice!", "warning");
      }
    },
    clickDelete:function(item){
      this.deleteDetail = Object.assign({},item);
      let tmpList = [];
      tmpList.push(...this.tempList);
      this.tempList.splice(0);
      this.totalPrice = 0;
      for(var i=0;i<tmpList.length;i++){
          let temp = tmpList[i];
          if(temp.invDetailId!=this.deleteDetail.invDetailId){
            this.tempList.push(temp);
            this.totalPrice = this.totalPrice + temp.amount;
          }
      }
    },
      clickEdit:function(item){
        this.noteIndex = 0;
      this.invDetailDto.invDetailId = item.invDetailId;
      this.qty = item.qty;
      this.price=item.price;
      this.amount=item.amount;
      this.detailRemark=item.detail;
      this.noteDto=item.note;
      this.remark=item.remark;
      this.invDetailDto = Object.assign({},item);
      this.addOrupdate = "UPDATE";
      this.deleteDetail = Object.assign({},item);
    },
      clickAdd:function(){
        if(this.qty>0 && this.price>0 ){
        if(this.addOrupdate == "ADD"){
        let tempObj = {};
        this.invDetailId = Number(this.invDetailId)+1;
        this.invDetailDto.invDetailId = this.invDetailId;
        this.invDetailDto.qty=this.qty;
        this.invDetailDto.price=this.price;
        this.invDetailDto.amount=this.amount;
        this.invDetailDto.remark=this.remark;
        this.invDetailDto.note=this.noteDto;
        this.invDetailDto.detail=this.detailRemark;
        tempObj = Object.assign({},this.invDetailDto);
        this.tempList.push(tempObj);        
        this.totalPrice = this.totalPrice + this.amount;
        this.qty=0;
        this.price=0;
        this.amount=0;
        this.detailRemark="";
        this.$nextTick(() => this.$refs.noteRef.focus());
        }else{
          this.totalPrice = 0;
          let tempObj = {};
          tempObj = Object.assign({},this.invDetailDto);
          let tmpList = [];
          tmpList.push(...this.tempList);
          this.tempList.splice(0);
          for(var i=0;i<tmpList.length;i++){
             let temp = tmpList[i];
             if(temp.invDetailId!=Number(this.deleteDetail.invDetailId)){
               this.tempList.push(temp);
               this.totalPrice = this.totalPrice + temp.amount;
             }else{
              tempObj.note=this.noteDto;
              tempObj.qty=this.qty;
              tempObj.price=this.price;
              tempObj.amount=this.amount;
              tempObj.detail=this.detailRemark;
              tempObj.remark=this.remark;
            this.tempList.push(tempObj);
            this.totalPrice = this.totalPrice + tempObj.amount;
          }
          
          }
          this.qty=0;
        this.price=0;
        this.amount=0;
        this.detailRemark="";
          this.addOrupdate="ADD";
          this.$nextTick(() => this.$refs.qtyRef.focus());
        }        
        }else{
         this.$swal("Warning!", "Please set qyt and price should be greater than zero!", "warning");
      }
      },
      customerListMethod: function () {
      customerService
        .getCustomer()
        .then((response) => {
          this.customerList.splice(0);
          this.customerList.push(...response);
          if(this.saveOrupdateAll=="SAVE"){
            this.customerDto = this.customerList[0];
          }else{
            this.customerDto=this.invoiceDto.customer;
          }
          
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    getCashListMethod: function () {
      billService
        .getCashTypes()
        .then((response) => {
          this.cashtypeList.splice(0, this.cashtypeList.length);
          this.cashtypeList.push(...response);
          this.cashtype = this.cashtypeList[0];
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    noteListMethod: function () {
      noteService
        .getNoteList()
        .then((response) => {
          this.noteList.splice(0, this.noteList.length);
          this.noteList.push(...response);
          this.noteDto = this.noteList[0];
        })
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });
    },
    exchangeListMethod: function () {
      exchangeService
        .getExchange()
        .then((response) => {
          this.exchangeList.splice(0, this.exchangeList.length);
          this.exchangeList.push(...response);
          this.exchangeDto = this.exchangeList[0];
        })
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });
    },
      formatDate(datePicker) {
      const [year, month, day] = datePicker.split("-");
      return `${day}-${month}-${year}`;
    },
    formatPicker(receivedDate) {
      const [day, month,year ] = receivedDate.split("-");
      return `${year}-${month}-${day}`;
    },
    },
    watch: {
      requestPicker() {
      this.receivedDate = this.formatDate(this.requestPicker);
    },
    noteDto(){
      if(this.noteIndex!=0 || this.addOrupdate!='UPDATE'){
      this.remark=this.noteDto.remark;
      }else{
        this.noteIndex = 1;
      }
    },
    exchangeDto(){
      this.exRate=this.exchangeDto.exchangeRate;
    },
    customerDto(){
      this.phone=this.customerDto.phone;
      this.address=this.customerDto.address; 
    },
    qty(){
      this.amount = (Number(this.qty)*Number(this.price));
    },
    price(){
      this.amount = (Number(this.qty)*Number(this.price));
    },
    totalPrice(){      
      this.balance=(Number(this.totalPrice)-Number(this.advancePaid)-Number(this.otherPayment));
    },
    advancePaid(){      
      this.balance=(Number(this.totalPrice)-Number(this.advancePaid)-Number(this.otherPayment));
    },
  },
    components: {exchange,note},
  };
  </script>
  <style scoped>
  .v-data-table td,
  .v-data-table th {
    padding: 0 0px !important;
  }
  .login-full {
    height: 85vh;
  }
  </style>